// Child getters
import metaGetters from "./meta/metaGetters";
import adAccountGetters from "./meta/adAccountGetters";
import adCampaignGetters from "./meta/adCampaignGetters";
import facebookGetters from "./meta/facebook/facebookGetters";
import instagramGetters from "./meta/instagram/instagramGetters";

export default {
    ...metaGetters,
    ...adAccountGetters,
    ...adCampaignGetters,
    ...facebookGetters,
    ...instagramGetters
}
export class LocalStorageService {
  storeItem(itemName: string, item: string) {
    localStorage.setItem(itemName, item);
  }

  storeItemAsJSON(itemName: string, item: any) {
    const jsonString = JSON.stringify(item);
    localStorage.setItem(itemName, jsonString);
  }

  getItem(itemName: string): string | null {
    return localStorage.getItem(itemName);
  }

  getItemAsJSON(itemName: string): any | null {
    const item = localStorage.getItem(itemName);
    return item ? JSON.parse(item) : null;
  }

  setItemAsJSON(itemName: string, data: any): void {
    data && localStorage.setItem(itemName, JSON.stringify(data));
  }

  removeItem(itemName: string) {
    localStorage.removeItem(itemName);
  }
}

import axios from '../../../axios-auth';

const routePath = '/meta';

export default {
  getAdAccounts({ commit, getters }) {
    return axios
      .get(`${routePath}/getAdaccounts?access_token=${getters.getUserMetaCredentials.accessToken}`)
      .then((res) => {
        commit('storeFacebookAdAccounts', res.data);
      })
      .catch((error) => {
        console.log('action error', error.message);
        throw error;
      });
  },
};

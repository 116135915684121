  import { createI18n, I18n } from 'vue-i18n';

  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';

  const i18n: I18n = createI18n({
    locale: selectedLanguage,
    fallbackLocale: 'da',
    messages: {},
  });

  export default i18n;

<script setup lang="ts">
import AppBreadcrumb from "./AppBreadcrumb.vue";
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useLayout } from "@/layout/composables/layout";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

// Components
import AppNotificationPanel from "./AppNotificationsPanel.vue";

// Components
import PaymentModal from "@/views/payment/PaymentModal.vue";

// Initializations
const route = useRoute();
const store = useStore();

// Referneces
const isNotificationPanelVisible = ref(false);
const notificationIconRef = ref(null);

// Computed
const unreadNotifications = computed(() => store.getters.unreadNotifications);

const unreadReceivedMessages = computed(() => {
  return store.state.communication.messages.receivedMessagesUnread.length;
});
const subscriptionPlanReady = computed(() => {
  return subscriptionPlan.value !== "loading...";
});
const subscriptionPlan = computed(() => {
  if (store.state.user && store.state.user.subscriptionPlan !== null) {
    return store.state.user.subscriptionPlan.name;
  } else {
    return "Loading...";
  }
});
const profileImage = computed(() => {
  return store.state.user &&
    store.state.user.profile &&
    store.state.user.profile.image &&
    store.state.user.profile.image.url
    ? store.state.user.profile.image.url
    : "/images/icons/avatar.png";
});

const { onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle } =
  useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      const notificationPanel = document.querySelector(".notification-panel");
      const notificationIcon = notificationIconRef.value?.$el; // Use ref

      if (
        notificationPanel &&
        !notificationPanel.contains(event.target) &&
        notificationIcon &&
        !notificationIcon.contains(event.target)
      ) {
        isNotificationPanelVisible.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

const showProfileSidebar = () => {
  onProfileSidebarToggle();
};

const onConfigButtonClick = () => {
  onConfigSidebarToggle();
};

const avatarStyle = ref({
  backgroundColor: "var(--surface-ground)", // Default background color
  color: "var(--text-color)", // Default icon color
});

// Function to change style on click
const setAvatarActiveStyle = () => {
  avatarStyle.value = {
    backgroundColor: "var(--primary-blue)", // Your blue color variable
    color: "#3b82f6", // Icon color when active
  };
};

watch(route, (newValue) => {
  if (newValue.path !== "/messages/inbox") {
    avatarStyle.value = {
      backgroundColor: "var(--surface-ground)",
      color: "var(--text-color)",
    };
  }
});

// Subscription related
const isSubscriptionModal = ref(false);
const openModal = () => {
  isSubscriptionModal.value = true;
};
const closeModal = () => {
  isSubscriptionModal.value = false;
};

const toggleNotificationPanel = () => {
  isNotificationPanelVisible.value = !isNotificationPanelVisible.value;
};

// Watchers
watch(isNotificationPanelVisible, (newVal) => {
  if (!newVal && unreadNotifications.value > 0) {
    store.dispatch("markAllNotificationsAsRead");
    unbindOutsideClickListener();
  } else {
    bindOutsideClickListener();
  }
});
</script>

<template>
  <div class="layout-topbar">
    <div class="topbar-start">
      <Button
        type="button"
        class="topbar-menubutton p-link p-trigger"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars"></i>
      </Button>

      <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>
    </div>

    <div class="topbar-end">
      <ul class="topbar-menu">
        <!-- <li class="topbar-search">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <InputText type="text" placeholder="Search" class="w-12rem sm:w-full" />
                    </span>
                </li> -->
        <!-- <li class="ml-3">
                    <Button icon="pi pi-cog" text rounded severity="secondary" @click="onConfigButtonClick"></Button>
                </li> -->

        <!-- TODO: Reimplement to enable payment -->
        <!-- <li class="mr-2">
                    <Button @click="openModal" :label="subscriptionPlan" severity="secondary" text />
                </li> -->

        <li>
          <!-- Avatar with custom size and background toggle -->
          <Avatar
            ref="notificationIconRef"
            icon="pi pi-bell"
            shape="circle"
            class="custom-avatar"
            :class="{ active: isNotificationPanelVisible }"
            v-if="unreadNotifications > 0"
            v-badge="unreadNotifications"
            @click="toggleNotificationPanel"
          />
          <Avatar
            ref="notificationIconRef"
            icon="pi pi-bell"
            shape="circle"
            class="custom-avatar"
            :class="{ active: isNotificationPanelVisible }"
            v-else
            @click="toggleNotificationPanel"
          />
        </li>

        <li class="topbar-profile">
          <Button type="button" class="p-link" @click="showProfileSidebar">
            <img :src="profileImage" alt="Profile"
          /></Button>
        </li>
      </ul>
    </div>
  </div>

  <transition name="fade">
    <AppNotificationPanel
      v-if="isNotificationPanelVisible"
      @dismiss="toggleNotificationPanel"
    />
  </transition>

  <transition name="fade">
    <div
      v-if="isNotificationPanelVisible"
      class="overlay"
      @click="closeNotificationPanel"
    ></div>
  </transition>

  <PaymentModal
    v-if="subscriptionPlanReady"
    :userSubscriptionPlan="subscriptionPlan"
    :visible="isSubscriptionModal"
    @update:visible="closeModal"
  ></PaymentModal>
</template>

<style lang="scss" scoped>
.custom-avatar {
  margin-top: 2px;
  width: 2.2rem; /* Custom width */
  height: 2.2rem; /* Custom height */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Ensures it's circular */
  background-color: transparent; /* Start with a transparent background */

  .pi.pi-bell {
    font-size: 1.2rem; /* Adjust the size of the icon */
    color: gray; /* Icon color */
  }

  &:hover {
    background-color: lightgray; /* Background becomes gray on hover */
    cursor: pointer; /* Show pointer cursor on hover */
  }

  &.active {
    background-color: var(--blue-100); /* Stay blue when the panel is open */
    color: black; /* Optionally change the icon color when active */
  }

  .p-badge {
    background-color: red; /* Customize the badge color */
  }
}

.topbar-profile {
  width: 40px;
  height: 40px;
}

/* Fade Transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black */
  z-index: 500; /* Make sure it's below the notification panel */
}
</style>

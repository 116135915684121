<script setup>
import { ref, computed, watch, onMounted, defineAsyncComponent } from "vue";

// Components
const WelcomeModal = defineAsyncComponent(() => import('./pages/auth/onboarding-modals/WelcomeModal.vue'));
const WelcomeModalGoogleLogin = defineAsyncComponent(() => import('./pages/auth/onboarding-modals/WelcomeModalGoogleLogin.vue'));

// Store
import { useStore } from "vuex";
const store = useStore();

// References
const title = ref("");
const message = ref("");
const group = ref("");
const showModal = ref(false);

// Computed 
const toast = computed(() => {
    if (store.state.toast || store.state.toast !== null) {
        return store.state.toast;
    } else {
        return null;
    }
});

const username = computed(() => store.state.user.name);

// Watchers
watch(toast, async (newValue) => {
    if (newValue) {
        title.value = newValue.title;
        message.value = newValue.message;
        group.value = newValue.group;
    }
}, { immediate: true });

watch(() => store.state.user.userType, (newUserType) => {
    setTimeout(() => {
        if (newUserType === "undefined") {
            showModal.value = true;
        }
    }, 600);
}, { immediate: true });

// Lifecycle hooks
onMounted(() => {
    const authUserPromise = store.dispatch("getAuthUser");
    authUserPromise
        .then(response => {
            store.dispatch("getNotifications")
            
            if (response.data.userType == "undefined") {
                showModal.value = true;
            }

            if (response.data.profile === undefined) {
                store.dispatch('getProfileLogo', response.data.email)
                    .catch(error => {
                        console.error('Error getting getProfileLogo:', error);
                    });
            }
        })
        .catch((error) => {
            console.error("Error fetching user: " + error);
        });

    store.dispatch("initializeWebSocket");
    store.commit('storebusinessDataOnBehalfOf_UUID', localStorage.getItem('businessDataOnBehalfOf_UUID'))
    store.commit('storebusinessDataOnBehalfOf_Email', localStorage.getItem('businessDataOnBehalfOf_Email'))
    store.commit('storebusinessDataOnBehalfOf_Name', localStorage.getItem('businessDataOnBehalfOf_Name'))
});


// Functions 
function handleUserTypeSelection(payload) {
    store.dispatch('updateUserType', payload)
        .then(() => {
            showModal.value = false;
        })
        .catch(() => {
            console.error('Error updating user type');
        });
}
</script>

<template>
    <Toast group="block3" :style="{ width: '30rem' }">
        <template #message="{ message }">
            <div class="flex align-items-start flex-1">
                <i class="pi pi-envelope text-blue-500 text-2xl mr-3"></i>
                <div>
                    <span class="text-xl font-medium text-900">{{ message.summary }}</span>
                    <p class="text-700 mt-3">{{ message.detail }}</p>
                </div>
            </div>
        </template>
    </Toast>

    <WelcomeModalGoogleLogin v-if="store.state.user.companyName == ''" :visible="showModal" :username="username" @update:visible="val => showModal = val"
        @handleUserTypeSelection="handleUserTypeSelection" />

    <WelcomeModal v-else :visible="showModal" :username="username" @update:visible="val => showModal = val"
            @handleUserTypeSelection="handleUserTypeSelection" />
</template>

<style scoped></style>

// Services
import { handleNotification } from "@/service/webSocket/handleNotificationService";
import { handlePartnershipRequest } from "@/service/webSocket/handlePartnershipRequestService";
import { handleMessage } from "@/service/webSocket/handleMessageService";

// Enums
import { WebSocketMessageType } from "@/enums/websockets/WebSocketMessageTypes";

export default {
    initializeWebSocket({ state, commit }) {
        const ws = new WebSocket(`${import.meta.env.VITE_WEBSOCKET_URL}?token=${localStorage.getItem('token')}`);

        ws.addEventListener('open', () => {
            console.log('WebSocket Connected');
            commit('SET_WS', ws);

            // Send a pong back to the server when you receive a ping
            ws.addEventListener('ping', () => {
                ws.pong('keepalive');
            });

            // Periodically send a ping message
            setInterval(() => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(`ping from ${state.user.uuid}`);
                }
            }, 30000);
        });

        ws.onmessage = function (event) {
            const message = JSON.parse(event.data);

            console.log('WebSocket message:', message);

            switch (message.type) {
                case WebSocketMessageType.NOTIFICATION:
                    handleNotification(message.data.fullNotification, commit, state);
                    break;
                case WebSocketMessageType.CHAT_MESSAGE:
                    handleMessage(message.data, commit, state);
                    break;
                case WebSocketMessageType.PARTNERSHIP_REQUEST:
                    handlePartnershipRequest(message.data, commit);
                    break;
                case WebSocketMessageType.SYSTEM_ALERT:
                    handleSystemAlert(message.data, commit);
                    break;
                default:
                    console.warn('Unknown WebSocket message type:', message.type);
            }
        };

        ws.addEventListener('message', (event) => {
            const data = JSON.parse(event.data);
            commit('SET_WS_DATA', data);
        });

        ws.addEventListener('close', (event) => {
            console.log('WebSocket closed: ', event);
            commit('SET_WS', null);
        });
    },
};

import { CommentTypeEnum } from '@/enums/comments/CommentTypeEnum';

export function handleMessage(incomingRequest: any, commit: any, state: any) {
  console.log('handleMessage incomingRequest: ', incomingRequest);

  switch (incomingRequest.type) {
    case CommentTypeEnum.MESSAGE:
      commit('pushMessageToReceivedMessagesQueue', incomingRequest);
      break;

    case CommentTypeEnum.COMMENT:
      commit('pushCommentToMessage', {
        messageUUID: incomingRequest.parentMessageUUID,
        comment: {
          date: incomingRequest.date,
          fromCompanyName: incomingRequest.fromCompanyName,
          fromUserUUID: incomingRequest.fromUserUUID,
          message: incomingRequest.message,
          profileImage: incomingRequest.profileImage,
        }
      });

      break;
  }
}

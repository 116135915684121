// Service
import { MetaService } from '@/service/medias/MetaService';

// Initialize service
const metaService = new MetaService();

export default {
  user(state) {
    return state.user;
  },
  getUserEmail(state) {
    return state.user.email;
  },

  getUserMetaCredentials(state) {
    return state.user.platformCredentials.meta.data;
  },

  getStoredInstagramId(state) {
    return state.user.platformCredentials.meta.data.pages.data?.find((page) => page.instagram?.id)?.instagram?.id || null;
  },
};

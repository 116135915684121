import { NotificationEnum } from "@/enums/notifications/NotificationType";

export function handleNotification(notification: any, commit: any, state: any) {

    switch (notification.type) {
        case NotificationEnum.GENERIC:
            commit('pushNotificationToQueue', notification);
            if (state.toast) {
                state.toast.add({
                    severity: 'info',
                    summary: notification.title,
                    detail: notification.message,
                    group: 'block3',
                    life: 5000
                });
            } else {
                console.error('Toast instance is not initialized.');
            }
            break;

        case NotificationEnum.NEW_MESSAGE:
            commit('pushNotificationToQueue', notification);
            if (state.toast) {
                state.toast.add({
                    severity: 'info',
                    summary: notification.title,
                    detail: notification.message,
                    group: 'block3',
                    life: 5000
                });
            } else {
                console.error('Toast instance is not initialized.');
            }
            break;

        case NotificationEnum.NEW_COMMENT:
            commit('pushNotificationToQueue', notification);
            if (state.toast) {
                state.toast.add({
                    severity: 'info',
                    summary: notification.title,
                    detail: notification.message,
                    group: 'block3',
                    life: 5000
                });
            } else {
                console.error('Toast instance is not initialized.');
            }
            break;

        case NotificationEnum.PARTNERSHIP_REQUEST:
            commit('pushNotificationToQueue', notification);
            if (state.toast) {
                state.toast.add({
                    severity: 'info',
                    summary: notification.title,
                    detail: notification.message,
                    group: 'block3',
                    life: 5000
                });
            }
            break;

        default:
            console.warn('Unknown notification type:', notification.type);
    }
}
